import React from 'react';
import Image from 'next/image';
import { Paragraph, ButtonLayoutProps } from '@leafwell/components';
import StateSelect from './state-select';
import WysiwygContent from './wysiwyg-content';

export type MedicalCardCtaProps = {
  title?: string;
  description?: string;
  buttonLabel?: string;
};

const Icons: React.FC<{
  description?: string;
  imagePath?: string;
}> = ({ description, imagePath }) => {
  return (
    <li className="flex flex-col">
      <figure className="relative overflow-hidden grid items-center justify-center h-24">
        <Image src={imagePath} alt="" height={80} width={70} />
      </figure>
      <WysiwygContent
        content={description}
        largerParagraph={false}
        className="text-center max-w-36"
      />
    </li>
  );
};

const MedicalCardCta: React.FC<MedicalCardCtaProps> = ({
  title,
  description,
  buttonLabel,
}) => {
  console.log(description);
  return (
    <div className="not-prose mx-auto grid gap-y-6 md:justify-items-center rounded-md bg-zinc-400 p-6 md:py-10 mb-6 mt-6 max-w-3xl">
      <h2
        className="my-reset font-body text-3xl lg:text-5xl max-w-md text-center"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Paragraph
        className="my-reset text-lg text-center"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <ul className="grid grid-cols-2 md:grid-cols-4 gap-x-8 gap-y-4 mb-4">
        <Icons
          description="Larger purchase limits"
          imagePath="/icon-higher-possession.svg"
        />
        <Icons
          description="Access to higher potency strains"
          imagePath="/icon-access-quality-products.svg"
        />
        <Icons
          description="Save up to 40% on product taxes"
          imagePath="/icon-tax-savings.svg"
        />
        <Icons
          description="Enhanced legal protection"
          imagePath="/icon-legal-protection.svg"
        />
      </ul>
      <StateSelect
        gridClassName="md:w-100"
        buttonLabel={buttonLabel}
        buttonVariant={'primary' as ButtonLayoutProps['variant']}
      />
    </div>
  );
};

export default MedicalCardCta;
